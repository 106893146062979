'use client';

import { mapInstanceAtom } from '@/components/EVMap/DeckGLMap';
import { Navbar } from '@/components/Navbar';
import { Sidebar } from '@/components/Sidebar';
import { Tabbed } from '@/components/Tabbed';
import { useHydrateAppState } from '@/lib/appStateAtom';
import { ActionIcon, Tooltip, Transition } from '@mantine/core';
import { IconChevronLeft, IconChevronRight } from '@tabler/icons-react';
import { useAtomValue } from 'jotai';
import { useState } from 'react';

export const dynamic = 'force-dynamic';

export default function HomePage() {
  // Hydrate the app state on page load using defaults and URL params
  useHydrateAppState();

  const [opened, setOpened] = useState(true);
  const map = useAtomValue(mapInstanceAtom);

  return (
    <>
      <Navbar />
      <div className="flex flex-row w-full h-full overflow-hidden">
        <div className="grid w-full h-full min-h-screen grid-cols-[auto_1fr] relative">
          <div className="relative">
            <Transition
              mounted={opened}
              transition="scale-x"
              duration={150}
              keepMounted
              timingFunction="ease-in-out"
              onEntered={() => {
                setTimeout(() => map?.resize(), 50);
              }}
              onExited={() => {
                setTimeout(() => map?.resize(), 50);
              }}
            >
              {(styles) => {
                return (
                  <div
                    className="overflow-y-auto min-h-full h-screen w-[300px] max-w-[300px] min-w-[300px] sticky top-0 left-0 bottom-0 border-r-1 border-solid border-black"
                    style={styles}
                  >
                    <Sidebar />
                  </div>
                );
              }}
            </Transition>
            <div className="overflow-hidden absolute top-[5px] left-[100%] z-50 bg-gray-50 text-gray-50 rounded-e-full rounded-r-full drop-shadow-[0px_0px_10px_2px_rgba(0,0,0,0.25)] border-r-2 border-solid border-t-2 border-b-2">
              <Tooltip
                label={opened ? 'Collapse Sidebar' : 'Show Sidebar'}
                position="right"
                withArrow
              >
                <div
                  className="flex flex-row gap-2 items-center cursor-pointer text-gray-500"
                  onClick={() => {
                    setOpened(!opened);
                  }}
                >
                  <ActionIcon
                    variant="transparent"
                    color="gray"
                    size={50}
                    aria-label={opened ? 'Collapse Sidebar' : 'Show Sidebar'}
                  >
                    {!opened ? <IconChevronRight /> : <IconChevronLeft />}
                  </ActionIcon>
                </div>
              </Tooltip>
            </div>
          </div>

          <div className="grid w-full h-full min-h-screen">
            <Tabbed />
          </div>
        </div>
      </div>
    </>
  );
}
