import {
  DisplayType,
  GeoData,
  MarketGrowth,
  MetricData,
  PredictionPeriod,
  StateCode,
  VisMetric,
} from '@/lib/types';
import { ELayerNames } from '@/lib/MAP_CONSTANTS';
import { DataFilterExtension } from '@deck.gl/extensions';
import { GeoJsonLayer, GeoJsonLayerProps } from '@deck.gl/layers';
import { getElevation, getFillColor } from '../../utils';

export const infoLayer = (
  geo: GeoData,
  data: MetricData,
  predictionPeriod: PredictionPeriod,
  marketGrowth: MarketGrowth,
  visMetric: VisMetric,
  displayType: DisplayType,
  stateCode: StateCode[],
  layerVisibility: ELayerNames[]
) => {
  return new GeoJsonLayer<GeoJsonLayerProps>({
    id: ELayerNames.INFO,
    data: geo as unknown as GeoJsonLayerProps['data'],
    pickable: true,
    visible: layerVisibility.includes(ELayerNames.INFO),
    stroked: true,
    filled: true,
    wireframe: displayType !== '3D',
    extruded: displayType === '3D', // this influences the wireframe
    material: true,
    autoHighlight: true,
    highlightColor: [86, 141, 229, 100],
    // getLineColor: [86, 86, 86, 50], // influences wireframe line color
    getLineColor: [0, 0, 0, 50], // influences wireframe line color
    getLineWidth: 25,
    lineWidthScale: 0,
    lineWidthMinPixels: 1,
    lineJointRounded: true,
    getElevation: (info: any) => getElevation(data, info, visMetric),
    // @ts-expect-error
    elevationScale: displayType === '3D' ? 30000 : 0,

    // props added by DataFilterExtension, used to filter by state selection.
    getFilterValue: (feature: { properties: { STATE_ID: StateCode } }) =>
      stateCode.indexOf(feature.properties.STATE_ID),
    filterRange: [0, 5],
    getFillColor: (info: any) => getFillColor(data, info, visMetric),
    updateTriggers: {
      getFilterValue: [stateCode],
      getFillColor: [
        data,
        displayType,
        geo,
        marketGrowth,
        predictionPeriod,
        visMetric,
      ],
      getElevation: [
        data,
        displayType,
        geo,
        marketGrowth,
        predictionPeriod,
        visMetric,
      ],
    },
    extensions: [new DataFilterExtension({ filterSize: 1 })],
  });
};
