'use client';

import { useCallback, useMemo } from 'react';
import {
  MantineReactTable,
  MRT_FilterTextInput,
  MRT_GlobalFilterTextInput,
  MRT_ShowHideColumnsButton,
  MRT_ToggleDensePaddingButton,
  MRT_ToggleFiltersButton,
  MRT_ToggleFullScreenButton,
  useMantineReactTable,
} from 'mantine-react-table';

import '@mantine/core/styles.css';
// import '@mantine/dates/styles.css'; //if using mantine date picker features
import 'mantine-react-table/styles.css'; //make sure MRT styles were imported in your app root (once)
import { useQueries } from '@tanstack/react-query';
import { appStateAtom } from '@/lib/appStateAtom';
import { useAtomValue } from 'jotai';
import {
  CountyGeoEntry,
  MetricData,
  RegionGranularity,
  VisMetric,
  ZipGeoEntry,
} from '@/lib/types';
import { Button, Tooltip } from '@mantine/core';
import { IconDownload } from '@tabler/icons-react';
import { mkConfig, generateCsv, download } from 'export-to-csv';
import { find } from 'lodash';
import * as queries from '@/lib/queries';

const ACCESSOR_KEY_TO_HUMAN_NAME = {
  zipcode: 'Zipcode',
  primary_city: 'Primary City',
  county_fips: 'FIPS',
  county_name: 'County',
  state_id: 'State',
  forecast_window: 'Forecast Window',
  market_growth: 'Market Growth',
  total_pop_count: 'Population Count',
  pred_total_vehicles: 'Pred Total Vehicles',
  pred_total_electric: 'Pred EV Vehicles',
  n_existing_ports: 'No. Of Existing Ports',
  estimated_port_need: 'Estimated Port Need',
  pred_perc_electric: 'Predicted % Of Total Vehicles',
  perc_traffic_covered: '% Of Electric Vehicle Traffic',
  energy_affinity: 'Energy Affinity',
} as const;

const dataDictionary = {
  county_fips: 'County FIPS code',
  zipcode: '5-digit USPS postal region',
  primary_city:
    'Primary city (county/state respectively) that the zipcode most closely maps to',
  county: 'County that the zipcode most closely maps to',
  state: 'State that the zipcode most closely maps to',
  forecast_window: (
    <div>
      Years into the future of the forecast window [0,1,3,5,10] (corresponds to
      Time Period parameter).
      <br />
      <br />
      <i>Note: forecast_window of 0 will not have a market_growth value.</i>
    </div>
  ),
  market_growth:
    'Expected level of growth in EV market [Conservative, Moderate, Aggressive]',
  total_pop_count:
    'Population (based on census data, forecasting models, and additional data sources)',
  pred_total_vehicles: 'Predicted # of total registered vehicles',
  pred_total_electric: 'Predicted # of total electric vehicles',
  pred_perc_electric:
    'Predicted % of total vehicles that are electric (shown as decimal, i.e. 0.01 = 1%)',
  pred_ev_traffic:
    'Predicted # of electric vehicles present based on commuter traffic patterns',
  n_existing_ports: '# of existing electric vehicle charge ports present',
  estimated_port_need:
    "# of new charge ports needed (based on user-supplied '# of New Ports Available')",
  perc_traffic_covered:
    '% of electric vehicle traffic that the (new + existing) charge ports can handle (shown as decimal, i.e. 0.01 = 1%)',
  energy_affinity: (
    <div>
      Relative interest in renewable energy technologies relative to overall
      population (higher score = more affinity).
      <br />
      <br />
      <i>Note: affinity values do not change with forecast_window,</i>
    </div>
  ),
} as const;

export const DataTable = () => {
  const appState = useAtomValue(appStateAtom);

  const [geo, evPortNeed, metric] = useQueries({
    queries: [
      queries.geoQuery(appState.regionGranularity),
      queries.evPortNeedQuery(
        appState.regionGranularity,
        appState.stateCode,
        appState.predictionPeriod,
        appState.marketGrowth,
        appState.portBudget,
        appState.visMetric
      ),
      queries.metricQuery(
        appState.regionGranularity,
        appState.stateCode,
        appState.predictionPeriod,
        appState.marketGrowth,
        appState.visMetric
      ),
    ],
  });

  const baseData =
    appState.visMetric === VisMetric.EvPortNeed ? evPortNeed : metric;

  const tableData = useMemo(() => {
    if (!geo.data || !baseData.data) return [];

    const getDetails = (key: 'COUNTY_FIPS' | 'ZIP', id: number) =>
      find(geo.data.features, (item) => item.properties[key] === id)
        ?.properties;

    return Object.entries(baseData.data as MetricData).reduce(
      (acc: any[], [key, value]) => {
        if (!value) return acc;
        if (appState.regionGranularity === RegionGranularity.ZipCode) {
          const { COUNTY_NAME, STATE_ID, STATE_NAME } = (getDetails(
            'ZIP',
            Number(key)
          ) ?? {}) as ZipGeoEntry;

          return [
            ...acc,
            {
              ...value,
              zipcode: key,
              county_name: COUNTY_NAME,
              state_id: STATE_ID,
              state_name: STATE_NAME,
              market_growth: value.market_growth ?? 'N/A',
            },
          ];
        } else {
          // County

          const { COUNTY_NAME, STATE_ID, STATE_NAME } = (getDetails(
            'COUNTY_FIPS',
            Number(key)
          ) ?? {}) as CountyGeoEntry;
          return [
            ...acc,
            {
              ...value,
              county_fips: key,
              county_name: COUNTY_NAME,
              state_id: STATE_ID,
              state_name: STATE_NAME,
              market_growth: value.market_growth ?? 'N/A',
            },
          ];
        }
      },
      []
    );
  }, [geo.data, baseData.data]);

  const columns = useMemo(() => {
    const renderTooltip = (
      { column }: any,
      dictKey?: keyof typeof dataDictionary
    ) => (
      <Tooltip
        multiline
        maw={220}
        withArrow
        transitionProps={{ duration: 200 }}
        label={
          dataDictionary[
            dictKey ||
              (column.columnDef.accessorKey as keyof typeof dataDictionary)
          ]
        }
      >
        <div className="whitespace-pre-wrap max-w-[120px] text-center">
          {column.columnDef.header}
        </div>
      </Tooltip>
    );

    let commonColumns = [
      {
        accessorKey: 'county_name',
        header: ACCESSOR_KEY_TO_HUMAN_NAME.county_name,
        Header: ({ column }: any) => renderTooltip({ column }, 'county'),
        maxSize: 120,
      },
      {
        accessorKey: 'state_id',
        header: ACCESSOR_KEY_TO_HUMAN_NAME.state_id,
        Header: ({ column }: any) => renderTooltip({ column }, 'state'),
        maxSize: 110,
      },
      {
        accessorKey: 'forecast_window',
        header: ACCESSOR_KEY_TO_HUMAN_NAME.forecast_window,
        Header: renderTooltip,
      },
      {
        accessorKey: 'market_growth',
        header: ACCESSOR_KEY_TO_HUMAN_NAME.market_growth,
        Header: renderTooltip,
      },
      {
        accessorKey: 'total_pop_count',
        header: ACCESSOR_KEY_TO_HUMAN_NAME.total_pop_count,
        Header: renderTooltip,
        Cell: ({ cell }: any) => cell.getValue().toLocaleString('en-US'),
        minSize: 140,
      },
      {
        accessorKey: 'pred_total_vehicles',
        header: ACCESSOR_KEY_TO_HUMAN_NAME.pred_total_vehicles,
        Cell: ({ cell }: any) => cell.getValue().toLocaleString('en-US'),
        Header: renderTooltip,
      },
      {
        accessorKey: 'pred_total_electric',
        header: ACCESSOR_KEY_TO_HUMAN_NAME.pred_total_electric,
        Cell: ({ cell }: any) => cell.getValue().toLocaleString('en-US'),
        Header: renderTooltip,
      },
      {
        accessorKey: 'n_existing_ports',
        header: ACCESSOR_KEY_TO_HUMAN_NAME.n_existing_ports,
        Header: renderTooltip,
      },
      {
        accessorKey: 'estimated_port_need',
        header: ACCESSOR_KEY_TO_HUMAN_NAME.estimated_port_need,
        Header: renderTooltip,
        minSize: 140,
        hidden: appState.visMetric !== VisMetric.EvPortNeed,
      },
      {
        accessorKey: 'pred_perc_electric',
        header: ACCESSOR_KEY_TO_HUMAN_NAME.pred_perc_electric,
        Cell: ({ cell }: any) =>
          (cell.getValue() * 100).toLocaleString('en-US', {
            minimumFractionDigits: 1,
            maximumFractionDigits: 1,
          }),
        Header: renderTooltip,
        minSize: 140,
      },
      {
        accessorKey: 'perc_traffic_covered',
        header: ACCESSOR_KEY_TO_HUMAN_NAME.perc_traffic_covered,
        Cell: ({ cell }: any) =>
          (cell.getValue() * 100).toLocaleString('en-US', {
            minimumFractionDigits: 1,
            maximumFractionDigits: 1,
          }),
        Header: renderTooltip,
        hidden: appState.visMetric !== VisMetric.EvPortNeed,
      },
      {
        accessorKey: 'energy_affinity',
        header: ACCESSOR_KEY_TO_HUMAN_NAME.energy_affinity,
        Cell: ({ cell }: any) => Math.round(cell.getValue()),
        Header: renderTooltip,
      },
    ];
    if (appState.regionGranularity === RegionGranularity.County) {
      return [
        {
          accessorKey: 'county_fips',
          header: ACCESSOR_KEY_TO_HUMAN_NAME.county_fips,
          Header: renderTooltip,
          size: 120,
        },
        ...commonColumns,
      ];
    } else {
      return [
        {
          accessorKey: 'zipcode',
          header: ACCESSOR_KEY_TO_HUMAN_NAME.zipcode,
          Header: renderTooltip,
          size: 120,
        },
        ...commonColumns,
      ];
    }
  }, [appState.regionGranularity, appState.visMetric]);

  const handleExportData = useCallback(() => {
    const csvConfig = mkConfig({
      fieldSeparator: ',',
      decimalSeparator: '.',
      filename: `Terrata-Export-${new Date().toISOString()}`,
      columnHeaders: Object.entries(ACCESSOR_KEY_TO_HUMAN_NAME).reduce(
        (acc, [key, value]) => {
          if (!columns.map((col) => col.accessorKey).includes(key)) return acc;
          // @ts-expect-error
          if (columns.find((col) => col.accessorKey === key)?.hidden)
            return acc;

          return [
            ...acc,
            {
              key,
              displayLabel: value,
            },
          ];
        },
        [] as { key: string; displayLabel: string }[]
      ),
    });

    const csv = generateCsv(csvConfig)(tableData);
    download(csvConfig)(csv);
  }, [tableData]);

  const table = useMantineReactTable({
    columns,
    data: tableData,
    defaultColumn: {
      minSize: 120,
      // size: 130,
    },

    enableColumnResizing: true,
    initialState: {
      density: 'xs',
      pagination: {
        pageIndex: 0,
        pageSize: 30,
      },
      columnVisibility: {
        ...columns.reduce(
          // @ts-expect-error
          (acc, col) => ({ ...acc, [col.accessorKey]: !col.hidden }),
          {}
        ),
      },
    },
    state: {
      columnOrder: columns.map((col) => col.accessorKey),
      columnVisibility: {
        ...columns.reduce(
          // @ts-expect-error
          (acc, col) => ({ ...acc, [col.accessorKey]: !col.hidden }),
          {}
        ),
      },
    },

    renderTopToolbar: ({ table }) => (
      <div className="flex py-1 w-full justify-end items-center gap-1 pr-2">
        <Button
          variant="outline"
          color="gray"
          size="xs"
          leftSection={<IconDownload />}
          onClick={handleExportData}
        >
          Export Data
        </Button>
        <MRT_ToggleFiltersButton table={table} />
        <MRT_ShowHideColumnsButton table={table} />
        <MRT_ToggleDensePaddingButton table={table} />
        <MRT_ToggleFullScreenButton table={table} />
      </div>
    ),
  });

  return (
    <div className="grid self-center max-w-full w-full">
      <MantineReactTable table={table} />
    </div>
  );
};
