import { ELayerNames } from '@/lib/MAP_CONSTANTS';
import {
  Combobox,
  ActionIcon,
  CheckIcon,
  Group,
  useCombobox,
  Tooltip,
} from '@mantine/core';
import { IconBoxMultiple } from '@tabler/icons-react';
import { atom, useAtom, useAtomValue } from 'jotai';
import { memo } from 'react';

export const layerVisibilityAtom = atom<ELayerNames[]>([
  ELayerNames.INFO,
  ELayerNames.COUNTY,
  ELayerNames.TRAFFIC,
  ELayerNames.CHARGING_STATIONS,
]);

export const ToggleLayerVisibilityControl = ({
  enabledLayers,
}: {
  enabledLayers: any[];
}) => {
  const [layerVisibility, setLayerVisibility] = useAtom(layerVisibilityAtom);
  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
  });

  const v = [
    {
      label: 'Metric',
      value: ELayerNames.INFO,
    },
    {
      label: 'County Metadata',
      value: ELayerNames.COUNTY,
    },
    {
      label: 'Traffic',
      value: ELayerNames.TRAFFIC,
    },
    {
      label: 'Charging Stations',
      value: ELayerNames.CHARGING_STATIONS,
    },
  ].map((item) =>
    enabledLayers.includes(item.value)
      ? { ...item, disabled: false }
      : { ...item, disabled: true }
  );

  const options = v.map((item) => (
    <Combobox.Option
      disabled={item.disabled}
      value={item.value}
      key={item.value}
    >
      <Tooltip
        label={
          item.disabled ? 'Layer is not enabled' : `Show/Hide ${item.label}`
        }
      >
        <Group>
          {layerVisibility.includes(item.value) && <CheckIcon size={12} />}
          <span>{item.label}</span>
        </Group>
      </Tooltip>
    </Combobox.Option>
  ));
  return (
    <>
      <Combobox
        store={combobox}
        width={250}
        position="bottom-start"
        withArrow
        withinPortal={false}
        positionDependencies={[layerVisibility]}
        onOptionSubmit={(val) => {
          setLayerVisibility((current: ELayerNames[]) =>
            current.includes(val as ELayerNames)
              ? current.filter((item) => item !== (val as ELayerNames))
              : [...current, val as ELayerNames]
          );
        }}
      >
        <Combobox.Target>
          <ActionIcon
            variant="outline"
            color="gray"
            onClick={() => combobox.toggleDropdown()}
          >
            <IconBoxMultiple
              style={{ width: '60%', height: '70%' }}
              size={16}
              stroke={1.5}
            />
          </ActionIcon>
        </Combobox.Target>

        <Combobox.Dropdown>
          <Combobox.Options>{options}</Combobox.Options>
        </Combobox.Dropdown>
      </Combobox>
    </>
  );
};

export default memo(ToggleLayerVisibilityControl);
