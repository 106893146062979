import { DataLegend } from '@/components/DataLegend';
import { memo } from 'react';

// import './legend.css';
export const DataLegendControl = () => {
  return (
    <div className="mb-10">
      <DataLegend />
    </div>
  );
};

export default memo(DataLegend);
