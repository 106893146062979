'use client';

import { useQueries } from '@tanstack/react-query';
import { memo, useEffect } from 'react';
import { useAtomValue } from 'jotai';
import { VisMetric } from '@/lib/types';
import { appStateAtom } from '@/lib/appStateAtom';

import 'mapbox-gl/dist/mapbox-gl.css';
import { notifications } from '@mantine/notifications';
import dynamic from 'next/dynamic';
import * as queries from '@/lib/queries';

const DeckGLMap = dynamic(() => import('./DeckGLMap'), {
  ssr: false,
  loading: () => (
    <div className="flex items-center justify-center self-center h-full w-full">
      Loading map and tiles...
    </div>
  ),
});

export const EVMap = memo(() => {
  const appState = useAtomValue(appStateAtom);
  const [geo, evPortNeed, metric, traffic, chargingStation, meta] = useQueries({
    queries: [
      queries.geoQuery(appState.regionGranularity),
      queries.evPortNeedQuery(
        appState.regionGranularity,
        appState.stateCode,
        appState.predictionPeriod,
        appState.marketGrowth,
        appState.portBudget,
        appState.visMetric
      ),
      queries.metricQuery(
        appState.regionGranularity,
        appState.stateCode,
        appState.predictionPeriod,
        appState.marketGrowth,
        appState.visMetric
      ),
      queries.trafficQuery(appState.trafficDestinationZips),
      queries.chargingStationQuery(
        appState.showChargingStations,
        appState.stateCode
      ),
      queries.countyMetaQuery(appState.stateCode, appState.countyMetadataType),
    ],
  });

  const isPending =
    geo.isFetching || metric.isFetching || evPortNeed.isFetching;
  const isSuccess = (geo.isSuccess && metric.isSuccess) || evPortNeed.isSuccess;
  const error = geo.error || metric.error || evPortNeed.error;

  useEffect(() => {
    if (isPending) {
      notifications.show({
        id: 'fetching',
        color: '#2CAE4A',
        title: 'Fetching data',
        message: 'Thank you for your patience...',
        autoClose: false,
      });
    }
    if (error) {
      notifications.show({
        id: 'fetching', // 'error
        title: 'Oh no',
        color: 'red',
        message: error.message,
      });
    }
    if (isSuccess) {
      notifications.update({
        id: 'fetching',
        title: 'Success',
        color: '#2CAE4A',
        message: 'Data loaded successfully',
        autoClose: 2000,
      });
    }
  }, [isPending, isSuccess, error]);

  const baseData =
    appState.visMetric === VisMetric.EvPortNeed ? evPortNeed : metric;
  return (
    <div className="h-full">
      <DeckGLMap
        geo={geo.data}
        data={baseData.data ?? []}
        trafficData={traffic.data}
        chargingStationData={chargingStation.data}
        meta={meta.data}
      />
    </div>
  );
});

EVMap.displayName = 'EVMap';
