'use client';
// import DeckGLOverlay from '@deck.gl/react';
import { MapboxOverlay } from '@deck.gl/mapbox';
import { DeckProps } from '@deck.gl/core';
import { Map, useControl, MapboxMap } from 'react-map-gl';

import { appStateAtom } from '@/lib/appStateAtom';
import {
  CountyMetadata,
  ECountyMetadata,
  GeoData,
  MetricData,
  TrafficData,
} from '@/lib/types';
import { atom, useAtom, useAtomValue } from 'jotai';
import { memo, useEffect, useMemo, useState } from 'react';
import { getTooltip } from './utils';
import { ELayerNames, INITIAL_VIEW_STATE } from '@/lib/MAP_CONSTANTS';

import ToggleLayerVisibilityControl, {
  layerVisibilityAtom,
} from './controls/ToggleLayerVisibilityControl';
import CustomControlsOverlay from './controls/CustomControlsOverlay';
import {
  clusterLayer,
  countyMetadataLayer,
  infoLayer,
  trafficLayer,
} from './layers';
import DataLegendControl from './controls/DataLegendControl';
import MetricHeatMap from './controls/MetricHeatMap';

export const mapInstanceAtom = atom<MapboxMap | null>(null);

function DeckGLOverlay(props: DeckProps) {
  const overlay = useControl<MapboxOverlay>(() => new MapboxOverlay(props));
  overlay.setProps(props);
  return null;
}
export const DeckGLMap = memo(
  ({
    geo,
    data,
    trafficData,
    chargingStationData,
    meta,
  }: {
    geo: GeoData;
    data: MetricData;
    trafficData: TrafficData | null;
    chargingStationData: any;
    meta: CountyMetadata;
  }) => {
    const appState = useAtomValue(appStateAtom);
    const {
      mapStyle,
      predictionPeriod,
      marketGrowth,
      visMetric,
      displayType,
      stateCode,
      countyMetadataType,
      showChargingStations,
      trafficDestinationZips,
    } = appState;

    const showTraffic = trafficDestinationZips.length > 0;

    const [map, setMap] = useAtom(mapInstanceAtom);
    const layerVisibility = useAtomValue(layerVisibilityAtom);

    const infoLayerInstance = useMemo(() => {
      return infoLayer(
        geo,
        data,
        predictionPeriod,
        marketGrowth,
        visMetric,
        displayType,
        stateCode,
        layerVisibility
      );
    }, [
      geo,
      data,
      predictionPeriod,
      marketGrowth,
      visMetric,
      stateCode,
      layerVisibility,
      displayType,
    ]);

    const countyMetadataLayerInstance = useMemo(() => {
      return countyMetadataLayer(
        meta,
        displayType,
        layerVisibility,
        countyMetadataType
      );
    }, [meta, displayType, layerVisibility, countyMetadataType]);

    const trafficLayerInstance = useMemo(() => {
      return trafficLayer(trafficData, displayType, layerVisibility);
    }, [trafficData, displayType, layerVisibility]);

    const clusterLayerInstance = useMemo(() => {
      return clusterLayer(
        chargingStationData,
        showChargingStations,
        layerVisibility
      );
    }, [chargingStationData, showChargingStations, layerVisibility]);

    const style = useMemo(() => {
      switch (mapStyle) {
        case 'Default':
          return 'mapbox://styles/mapbox/light-v10';
        case 'Traffic':
          return 'mapbox://styles/mapbox/navigation-day-v1';
        case 'Street':
          return 'mapbox://styles/mapbox/streets-v11';
      }
    }, [mapStyle]);

    const [viewState, setViewState] = useState(INITIAL_VIEW_STATE);
    useEffect(() => {
      if (map) {
        let config = viewState;

        if (displayType === '3D') {
          config = {
            ...config,
            bearing: 15,
            pitch: 45,
          };
        } else {
          config = {
            ...config,
            bearing: INITIAL_VIEW_STATE.bearing,
            pitch: INITIAL_VIEW_STATE.pitch,
          };
          if (showTraffic) {
            config = {
              ...config,
              bearing: 0,
              pitch: 55,
            };
          }
        }
        setViewState(config);
      }
    }, [map, showTraffic, displayType]); // eslint-disable-line

    const onClick = (event: any) => {
      map?.easeTo({
        center: event.lngLat,
        zoom: map.getZoom() + 1,
        duration: 500,
      });
    };

    return (
      <div className="h-full relative">
        <Map
          {...viewState}
          interactiveLayerIds={[ELayerNames.CHARGING_STATIONS]}
          mapboxAccessToken="pk.eyJ1IjoibWFjaWVrLXJlY2VudGl2ZSIsImEiOiJjbHA3bzFtdTcwanltMmlwOGxwMjR4N3FjIn0.54bLhz2MbTmnqjN4E3OeXA"
          mapStyle={style}
          style={{ width: '100%', height: '100%' }}
          onLoad={({ target }) => {
            setMap(target);
          }}
          onClick={onClick}
          onMove={(evt: { viewState: any }) => {
            setViewState(evt.viewState);
          }}
          reuseMaps
        >
          <DeckGLOverlay
            layers={[
              infoLayerInstance,
              countyMetadataLayerInstance,
              trafficLayerInstance,
              clusterLayerInstance,
            ].filter(Boolean)}
            getTooltip={(info: any) => getTooltip(data, info, visMetric)}
          />
          <CustomControlsOverlay
            position="top-right"
            controlContainerClassNames="p-1"
          >
            <ToggleLayerVisibilityControl
              enabledLayers={[
                ELayerNames.INFO,
                countyMetadataType !== ECountyMetadata.Hidden
                  ? ELayerNames.COUNTY
                  : null,
                showTraffic ? ELayerNames.TRAFFIC : null,
                showChargingStations ? ELayerNames.CHARGING_STATIONS : null,
              ].filter(Boolean)}
            />
          </CustomControlsOverlay>
          <CustomControlsOverlay
            position="bottom-right"
            controlContainerClassNames="!bg-transparent !shadow-none"
          >
            <DataLegendControl />
          </CustomControlsOverlay>
          {/* <CustomControlsOverlay position="bottom-left"> */}
          {/*   <MetricHeatMap /> */}
          {/* </CustomControlsOverlay> */}
        </Map>
      </div>
    );
  }
);

DeckGLMap.displayName = 'DeckGLMap';
export default DeckGLMap;
