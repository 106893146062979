import { CountyMetadata, DisplayType, ECountyMetadata } from '@/lib/types';
import { ELayerNames } from '@/lib/MAP_CONSTANTS';
import { DataFilterExtension } from '@deck.gl/extensions';
import { GeoJsonLayer, GeoJsonLayerProps } from '@deck.gl/layers';

export const countyMetadataLayer = (
  meta: CountyMetadata,
  displayType: DisplayType,
  layerVisibility: ELayerNames[],
  countyMetadataType: ECountyMetadata
) => {
  if (!meta) return null;

  return new GeoJsonLayer<GeoJsonLayerProps>({
    id: ELayerNames.COUNTY,
    data: meta as unknown as GeoJsonLayerProps['data'],
    visible: layerVisibility.includes(ELayerNames.COUNTY),
    pickable: false,
    stroked: true,
    filled: true,
    wireframe: false,
    // FIXME: this is not in the json right now
    // getElevation: d => d.elevation,
    // elevationScale: displayType === '3D' ? 30 : 0,
    extruded: displayType === '3D' ? true : false,
    material: true,
    autoHighlight: true,
    highlightColor: [86, 141, 229, 100],
    getLineColor: [70, 38, 199, 75],
    getLineWidth: 4,
    lineWidthScale: 0,
    lineWidthMinPixels: 2,
    lineJointRounded: true,
    getFillColor: (o: any) => o.fill_color,
    updateTriggers: {
      getFillColor: [meta, countyMetadataType],
    },
    extensions: [new DataFilterExtension({ filterSize: 1 })],
  });
};
