import { cn } from '@/lib/utils';
import { IControl } from 'mapbox-gl';
import { ReactElement, cloneElement, memo } from 'react';
import { createPortal } from 'react-dom';
import { ControlPosition, MapboxMap, useControl } from 'react-map-gl';

type Config = {
  controlContainerClassNames?: string;
  style?: string;
  position: ControlPosition;
  redraw?: () => void;
};

type OverlayProps = {
  /**
   * any classes you want to apply to the control container
   * this will be appended to the default classes
   * and you probably need to use !important just fyi
   */
  controlContainerClassNames?: string;
  /**
   * this is the style of the control container
   * supply a string for inline css not a JS object.
   * i.e. 'background-color: red !important; color: white;'
   **/
  style?: string;
  position: ControlPosition;
  children: ReactElement;
};

class OverlayControl implements IControl {
  _map: MapboxMap | null = null;
  _controlContainerClassNames: string | undefined;
  _style: string | undefined;
  _container: HTMLElement | null = null;
  _position: ControlPosition = 'bottom-right';
  _redraw?: () => void;

  constructor({
    controlContainerClassNames = '',
    style = '',
    position,
    redraw,
  }: Config) {
    this._position = position;
    this._controlContainerClassNames = controlContainerClassNames;
    this._style;
    if (redraw !== undefined) this._redraw = redraw;
  }

  onAdd(map: MapboxMap) {
    this._map = map;
    this._container = document.createElement('div');
    this._container.className = cn(
      'mapboxgl-ctrl',
      'mapboxgl-ctrl-group',
      this._controlContainerClassNames // use important to override the base rules
    );
    if (this._style !== undefined) this._container.style.cssText = this._style;

    if (this._redraw !== undefined) {
      map.on('move', this._redraw);
      this._redraw();
    }

    return this._container;
  }

  onRemove() {
    if (this._map === null || this._container === null) return;
    this._container.remove();
    if (this._redraw !== undefined) this._map.off('move', this._redraw);
    this._map = null;
  }

  getDefaultPosition?() {
    return this._position;
  }

  getMap() {
    return this._map;
  }

  getElement() {
    return this._container;
  }
}

export const CustomControlsOverlay = ({
  controlContainerClassNames = '',
  style,
  position,
  children,
}: OverlayProps) => {
  const ctrl = useControl<OverlayControl>(() => {
    return new OverlayControl({ position, style, controlContainerClassNames });
  });

  const map = ctrl.getMap();
  const elem = ctrl.getElement();
  if (map === null || elem === null) return;

  return createPortal(cloneElement(children, { map }), elem);
};

export default memo(CustomControlsOverlay);
