export const MIN_MAX_COUNTY_IDX = {
  pred_total_electric: { min: 0, max: 665388 },
  pred_perc_electric: { min: 0, max: 0.8172 },
  pred_ev_traffic: { min: 0, max: 1016927 },
  estimated_port_need: { min: 0, max: 59 },
  energy_affinity: { min: 9.995182530346977, max: 90.23934812399878 },
};

export const MIN_MAX_ZIP_IDX = {
  pred_total_electric: { min: 0, max: 92855 },
  pred_perc_electric: { min: 0, max: 0.838 },
  pred_ev_traffic: { min: 0, max: 77516 },
  estimated_port_need: { min: 0, max: 12 },
  energy_affinity: { min: 7.635621722052388, max: 98.50204266611648 },
};

export enum RegionGranularity {
  County = 'County',
  ZipCode = 'Zipcode',
}

export enum StateCode {
  AL = 'AL',
  GA = 'GA',
  MS = 'MS',
}

export enum VisMetric {
  ElectricVehicles = 'pred_total_electric',
  PercElectricVehicles = 'pred_perc_electric',
  NetEvTraffic = 'pred_ev_traffic',
  EvPortNeed = 'estimated_port_need',
  EnergyAffinity = 'energy_affinity',
}

export enum PredictionPeriod {
  Today = 0,
  One = 1,
  Three = 3,
  Five = 5,
  Ten = 10,
}

export enum MetricAlgorithm {
  Total = 'Total',
  NetNew = 'Netnew',
}

export enum MarketGrowth {
  Conservative = 'Conservative',
  Moderate = 'Moderate',
  Aggressive = 'Aggressive',
}

export enum DisplayType {
  '2D' = '2D',
  '3D' = '3D',
  'Hide' = 'Hide',
}

export enum ECountyMetadata {
  Hidden = 'Hidden',
  Demographics = 'Demographics',
  EnergyAffinity = 'EnergyAffinity',
}

export enum MapStyle {
  Default = 'Default',
  Street = 'Street',
  Traffic = 'Traffic',
}

export enum ESelectedTab {
  Map = 'map',
  Data = 'data',
}

export interface CountyDataEntry {
  forecast_window: 0 | 1 | 3 | 5 | 10;
  market_growth: null | MarketGrowth;
  total_pop_count: number;
  pred_total_vehicles: number;
  pred_total_electric: number;
  pred_ev_traffic: number;
  n_existing_ports: number;
  estimated_port_need: number;
  perc_traffic_covered: number;
  pred_perc_electric: number;
  energy_affinity: number;
  elevation_energy_affinity: number;
  elevation_pred_ev_traffic: number;
  elevation_pred_perc_electric: number;
  elevation_pred_total_electric: number;
  elevation_estimated_port_need: number;
  fill_color_energy_affinity: string; //"[255, 153, 153, 190]"
  fill_color_pred_ev_traffic: string; //"[255, 153, 153, 190]"
  fill_color_pred_perc_electric: string; //"[255, 153, 153, 190]"
  fill_color_pred_total_electric: string; //"[255, 153, 153, 190]"
  fill_color_estimated_port_need: [number, number, number, number];
}

export interface ZipDataEntry extends CountyDataEntry {
  primary_city: string;
}

export interface CountyGeoEntry {
  COUNTY_FIPS: number;
  COUNTY_NAME: string;
  STATE_ID: StateCode;
  STATE_NAME: string;
  POPULATION: number;
}

export interface ZipGeoEntry extends CountyGeoEntry {
  ZIP: number;
  CITY_NAME: string;
}

export type TrafficDataEntry = {
  home_city: string;
  h_zip: string;
  w_zip: string;
  h_lat: number;
  h_lon: number;
  w_lat: number;
  w_lon: number;
  state: string; // lowercase
  units_ev: number;
  units_ev_negative: number;
  units_ev_rk: number;
  arc_width: number;
};

export type MetricData =
  | {
      [key: string]: CountyDataEntry;
    }
  | {
      [key: string]: ZipDataEntry;
    };
export type GeoData = ZipGeoEntry[] | CountyGeoEntry[];
export type TrafficData = TrafficDataEntry[];

export type Geometry = {
  readonly coordinates: Array<Array<Array<number[]>>>;
  readonly type: string;
};

export type CountyMetaDemographicsEntry = {
  readonly county: string;
  readonly sample: string;
  readonly total_pop_count: number;
  readonly pop_density: number;
  readonly pop_med_age: number;
  readonly med_hh_income: number;
  readonly pop_perc_democrat: number;
  readonly middle_income_ratio: number;
  readonly young_age_ratio: number;
  readonly affinity_score: number;
  readonly geometry: Geometry;
  readonly cluster: number;
  readonly fill_color: number[];
};

export type CountyMetadata = CountyMetaDemographicsEntry[];

export type TChargingStationEntry = {
  longitude: number;
  latitude: number;
  station_name: string;
  ev_network: string;
  ev_connector_types: string;
  facility_type: string;
  street_address: string;
  city: string;
  state: string;
  zip: number;
  station_phone: string;
  n_ports: number;
  zip_info: string;
  // icon_data: {
  //   url: string;
  //   width: number;
  //   height: number;
  //   anchorY: number;
  // };
};
