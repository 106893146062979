import { AppState, schemaAppState } from '@/lib/appStateAtom';
import {
  CountyGeoEntry,
  ZipDataEntry,
  PredictionPeriod,
  VisMetric,
  MetricData,
  ZipGeoEntry,
  TChargingStationEntry,
  TrafficDataEntry,
} from '@/lib/types';
import { ELayerNames } from '@/lib/MAP_CONSTANTS';

// const parseAndCoerceInputs = (obj: Partial<AppState>) => {
//   const res = schemaAppState
//     .pick(Object.keys(obj).reduce((acc, key) => ({ ...acc, [key]: true }), {}))
//     .safeParse(obj);

//   assert(
//     res.success,
//     `Assertion failed when parsing the values supplied ${res.error?.errors
//       .map((e) => [e.path, e.message])
//       .join('-')}`
//   );

//   return res.data as AppState;
// };

const tooltipStyle = {
  backgroundColor: 'rgba(0,0,0,0.8)',
  color: 'white',
  '-webkit-font-smoothing': 'antialiased',
  '-moz-osx-font-smoothing': 'grayscale',
  'border-radius': '15px',
  border: '2px solid #000',
  'font-family':
    '-apple-system,system-ui,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji"',
  'line-height': 1.45,
};

type ChargingStationInfo = {
  layer: {
    id: ELayerNames.CHARGING_STATIONS;
  };
  object: TChargingStationEntry & { cluster?: boolean };
};

type TrafficInfo = {
  layer: {
    id: ELayerNames.TRAFFIC;
  };
  object: TrafficDataEntry;
};

type GeoInfo = {
  layer: {
    id: ELayerNames.INFO;
  };
  object: { properties: ZipGeoEntry | CountyGeoEntry };
};

type TInfo = ChargingStationInfo | GeoInfo | TrafficInfo;

function getChargingStationTooltip(info: ChargingStationInfo) {
  if (info?.object && 'cluster' in info.object && info.object.cluster) {
    return null;
  }
  if (info.object) {
    const {
      station_name,
      ev_network,
      street_address,
      city,
      state,
      zip,
      station_phone,
      ev_connector_types,
      n_ports,
    } = info.object as any;

    return {
      html: `<div>
      <strong>Station:</strong> ${station_name} (${ev_network})<br>
      <strong>Address:</strong> ${street_address}, ${city}, ${state.toUpperCase()}, ${zip}<br>
      <strong>Phone #:</strong> ${station_phone}<br>
      <strong>Ports:</strong> ${ev_connector_types.replace(
        /\n/g,
        ''
      )} (${Math.round(n_ports)})
        </div>`,
      style: tooltipStyle,
    };
  }
  return null;
}

function getTrafficTooltip(info: TrafficInfo) {
  const { h_zip, w_zip, units_ev_rk } = info?.object ?? {};
  if (!h_zip || !w_zip || !units_ev_rk) {
    return null;
  }
  return {
    html: `<div>
    <strong>Origin Zip:</strong> ${h_zip}<br>
    <strong>Destination Zip:</strong> ${w_zip}<br>
    <strong>EV Traffic Rank:</strong> ${Math.round(units_ev_rk)}
    </div>`,
    style: tooltipStyle,
  };
}

const toPercentageString = (value: number) => {
  return `${(value * 100).toLocaleString('en-US', {
    minimumFractionDigits: 1,
    maximumFractionDigits: 1,
  })}%`;
};
const toLocaleString = (value: number) => value.toLocaleString('en-US');

export const getTooltip = <T extends { layer: any; object: any } = TInfo>(
  dataSet: MetricData,
  info: T,
  visMetric: VisMetric
): null | string | { html: string; style: object } => {
  if (info?.layer?.id === ELayerNames.CHARGING_STATIONS) {
    return getChargingStationTooltip(info);
  }
  if (info?.layer?.id === ELayerNames.TRAFFIC) {
    return getTrafficTooltip(info);
  }

  if (!info?.object?.properties) {
    return null;
  }

  let hasZip = false;
  let entryKey = undefined;
  if ('ZIP' in info.object.properties) {
    entryKey = info.object.properties.ZIP;
    hasZip = true;
  } else {
    entryKey = info.object.properties.COUNTY_FIPS;
  }
  const { COUNTY_NAME, POPULATION, STATE_NAME } = info.object
    .properties as CountyGeoEntry;
  const entry = dataSet?.[`${entryKey}`];

  if (!entry) {
    return null;
  }

  const {
    pred_ev_traffic,
    pred_total_electric,
    pred_perc_electric,
    n_existing_ports,
    energy_affinity,
    estimated_port_need,
    perc_traffic_covered,
    total_pop_count,
  } = entry;

  return {
    html: `<div>
    <strong>${hasZip ? 'ZIP Code' : 'County'}: </strong> ${
      hasZip
        ? `${entryKey} (${(entry as ZipDataEntry).primary_city})`
        : COUNTY_NAME
    }<br>
    <strong>State: </strong> ${STATE_NAME}<br>
    <strong>Population: </strong> ${toLocaleString(total_pop_count)}<br>
    <strong>EV Registered (% Share): </strong> ${toLocaleString(
      pred_total_electric
    )} (${toPercentageString(pred_perc_electric)})<br>
    <strong>EV Traffic: </strong> ${toLocaleString(pred_ev_traffic)}<br>
    ${
      visMetric === VisMetric.EvPortNeed
        ? `<strong>EV Traffic Covered: </strong> ${toPercentageString(
            perc_traffic_covered
          )}<br>`
        : ''
    }
    <strong>Ports Existing: </strong> ${toLocaleString(n_existing_ports)}<br>
    ${
      visMetric === VisMetric.EvPortNeed
        ? `<strong>Ports Needed: </strong> ${estimated_port_need}<br>`
        : ''
    }
    <strong>Energy Affinity: </strong> ${Math.round(energy_affinity)}
    </div>`,
    style: tooltipStyle,
  };
};

export const getFillColor = (
  dataSet: MetricData,
  info: {
    properties: ZipGeoEntry | CountyGeoEntry;
  },
  visMetric: VisMetric
): [number, number, number, number] => {
  let entryKey = undefined;
  if ('ZIP' in info.properties) {
    entryKey = info.properties.ZIP;
  } else {
    entryKey = info.properties.COUNTY_FIPS;
  }

  const entry = dataSet?.[`${entryKey}`];
  const fill_color_string = entry?.[`fill_color_${visMetric}`];

  if (!fill_color_string) {
    return [255, 0, 0, 0];
  }

  const toArray = (fill_color_string as unknown as string)
    .replaceAll('[', '')
    .replaceAll(']', '')
    .split(',')
    .map(Number);
  return toArray as [number, number, number, number];
};

export const getElevation = (
  dataSet: MetricData,
  info: {
    properties: ZipGeoEntry | CountyGeoEntry;
  },
  visMetric: VisMetric
): number => {
  let entryKey = undefined;
  if ('ZIP' in info.properties) {
    entryKey = info.properties.ZIP;
  } else {
    entryKey = info.properties.COUNTY_FIPS;
  }

  const entry = dataSet?.[`${entryKey}`];
  const elevation = entry?.[`elevation_${visMetric}`];

  if (!elevation) {
    return 0;
  }

  return elevation;
};
