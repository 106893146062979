import { DisplayType, TrafficData, TrafficDataEntry } from '@/lib/types';
import { ELayerNames } from '@/lib/MAP_CONSTANTS';
import { ArcLayer } from '@deck.gl/layers';

export const trafficLayer = (
  trafficData: TrafficData | null,
  displayType: DisplayType,
  layerVisibility: ELayerNames[]
) => {
  if (!trafficData) return null;

  return new ArcLayer<TrafficDataEntry>({
    id: ELayerNames.TRAFFIC,
    data: trafficData,
    visible: layerVisibility.includes(ELayerNames.TRAFFIC),
    getSourcePosition: (d: TrafficDataEntry) => [d.h_lon, d.h_lat],
    getTargetPosition: (d: TrafficDataEntry) => [d.w_lon, d.w_lat],
    getSourceColor: [35, 16, 201, 175],
    getWidth: (d: TrafficDataEntry) => d.arc_width,
    getTargetColor: [26, 237, 118, 200],
    autoHighlight: true,
    pickable: true,
    updateTriggers: {
      getSourcePosition: [trafficData, displayType],
      getTargetPosition: [trafficData, displayType],
    },
    // transitions: {
    //   getSourcePosition: {
    //     duration: 600,
    //     easing: (x: number) => -(Math.cos(Math.PI * x) - 1) / 2, // ease-in-out-sine
    //     // entry: ([r, g, b]) => [r, g, b, 0],
    //   },
    //   getTargetPosition: {
    //     duration: 600,
    //     easing: (x: number) => -(Math.cos(Math.PI * x) - 1) / 2, // ease-in-out-sine
    //     // entry: ([r, g, b]) => [r, g, b, 0],
    //   },
    // },
  });
};
