import { ELayerNames } from '@/lib/MAP_CONSTANTS';
import IconClusterLayer from './icon-cluster-layer';
import type { IconLayerProps } from '@deck.gl/layers';

import iconAtlas from './data/location-icon-atlas3.png';
import iconMapping from './data/location-icon-mapping.json';
import { TChargingStationEntry } from '@/lib/types';

export const clusterLayer = (
  chargingStationData: TChargingStationEntry[],
  showChargingStations: boolean,
  layerVisibility: ELayerNames[]
) => {
  if (!showChargingStations) return null;

  const layerProps: IconLayerProps<{
    longitude: number;
    latitude: number;
  }> = {
    id: 'icon',
    data: chargingStationData,
    pickable: true,
    visible: layerVisibility.includes(ELayerNames.CHARGING_STATIONS),
    getPosition: (d: any) => [d.longitude, d.latitude],
    iconAtlas: iconAtlas.src,
    iconMapping,

    getSize: 4,

    sizeScale: 8,
    sizeMinPixels: 16,
    sizeMaxPixels: 64,
    getColor: [0, 0, 0, 175],
    updateTriggers: {
      getPosition: [chargingStationData, showChargingStations],
    },
  };

  return new IconClusterLayer({
    ...layerProps,
    id: ELayerNames.CHARGING_STATIONS,
    sizeScale: 40,
  });
};
