export enum ELayerNames {
  INFO = 'info',
  COUNTY = 'county',
  TRAFFIC = 'traffic',
  CHARGING_STATIONS = 'chargingStation',
}

export const INITIAL_VIEW_STATE = {
  latitude: 32,
  longitude: -86,
  zoom: 6,
  minZoom: 5,
  // maxZoom: 10,
  pitch: 0,
  bearing: 0,
};
