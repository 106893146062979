import { appStateAtom } from '@/lib/appStateAtom';
import { StateCode } from '@/lib/types';
import { cn } from '@/lib/utils';
import { Input, Checkbox } from '@mantine/core';
import { useAtom } from 'jotai';
import { useState } from 'react';

export const StateSelect = ({ disabled, data }: any) => {
  const [appState, setAppState] = useAtom(appStateAtom);

  const selectedValues = appState.stateCode;
  const [hovered, setHovered] = useState<StateCode | null>(null);
  const onChange = (checked: boolean, value: StateCode) => {
    if (checked) {
      setAppState({
        ...appState,
        stateCode: selectedValues.filter((v) => v !== value),
      });
    } else {
      setAppState({
        ...appState,
        stateCode: [...selectedValues, value],
      });
    }
  };
  return (
    <div>
      <Input.Wrapper label="State" className="flex flex-col gap-1">
        {data.map(({ label, value }: { label: string; value: StateCode }) => {
          const checked = selectedValues?.includes(value);
          return (
            <div
              key={value}
              className={cn(
                'cursor-pointer flex flex-col gap-2 border border-solid border-gray-300 rounded-md items-center px-4 py-1.5',
                checked && 'border-gray-400 bg-gray-100',
                disabled && 'cursor-not-allowed pointer-events-none',
                hovered === value && 'border-[#2CAE4A]'
              )}
              onMouseEnter={() => setHovered(value)}
              onMouseLeave={() => setHovered(null)}
              onClick={() => onChange(checked, value)}
            >
              <Checkbox
                radius={'lg'}
                label={label}
                className="text-sm grid grid-cols-[40px_auto] items-center gap-2 w-full"
                styles={{
                  label: {
                    cursor: 'pointer',
                  },
                  input: {
                    cursor: 'pointer',
                  },
                }}
                color="#2CAE4A"
                disabled={disabled}
                checked={checked}
                readOnly
                // onChange={() => onChange(checked, value)}
                value={value}
              />
            </div>
          );
        })}
      </Input.Wrapper>
    </div>
  );
};
