import { DataTable } from './DataTable';
import { EVMap } from './EVMap/EVMap';
import { useAtomValue } from 'jotai';
import { mapInstanceAtom } from './EVMap/DeckGLMap';
import { appStateAtom } from '@/lib/appStateAtom';
import { cn } from '@/lib/utils';
import { ESelectedTab } from '@/lib/types';
import { useEffect } from 'react';

export function Tabbed() {
  const map = useAtomValue(mapInstanceAtom);
  const appState = useAtomValue(appStateAtom);
  const selectedTab = appState.selectedTab;

  useEffect(() => {
    if (selectedTab === ESelectedTab.Map) {
      setTimeout(() => map?.resize(), 5);
    }
  }, [selectedTab]);

  return (
    <>
      <Tab
        selectedValue={selectedTab}
        value={ESelectedTab.Map}
        className="h-full w-full"
      >
        <EVMap />
      </Tab>

      <Tab selectedValue={selectedTab} value={ESelectedTab.Data}>
        <div className="pb-[100px] h-[calc(100vh-1.5rem)] overflow-auto">
          <div className="flex flex-col m-auto">
            <DataTable />
          </div>
        </div>
      </Tab>
      <footer className="fixed bottom-0 left-0 right-0 z-10">
        <div className="text-[12px] flex justify-center bg-slate-100 bg-opacity-25 rounded-tl-md">
          <a
            style={{
              color: '#3679b7',
              textDecoration: 'none',
              fontWeight: 'bold',
            }}
            href="mailto:support@recentiveanalytics.com"
          >
            support@recentiveanalytics.com &nbsp;&#x2022;&nbsp;
          </a>
          &copy; 2012 - 2024&nbsp;
          <a
            style={{
              color: '#3679b7',
              textDecoration: 'none',
              fontWeight: 'bold',
            }}
            href="http://www.recentiveanalytics.com"
          >
            Recentive Analytics
          </a>
          , All Rights Reserved.
        </div>
      </footer>
    </>
  );
}

const Tab = ({
  value,
  selectedValue,
  children,
  ...rest
}: {
  value: string;
  selectedValue: string;
  children: React.ReactNode;
  [key: string]: any;
}) => {
  return (
    <div
      {...rest}
      className={cn(
        rest.className,
        selectedValue === value ? 'block' : 'hidden'
      )}
    >
      {children}
    </div>
  );
};
