import { z } from 'zod';
import {
  RegionGranularity,
  VisMetric,
  MarketGrowth,
  DisplayType,
  StateCode,
  PredictionPeriod,
  MapStyle,
  ECountyMetadata,
  ESelectedTab,
} from './types';
import { atom, useAtomValue } from 'jotai';
import { useEffect } from 'react';
import queryString from 'query-string';
import { ExtractAtomValue } from 'jotai';
import { useHydrateAtoms } from 'jotai/utils';
import { useSearchParams } from 'next/navigation';
import { isEmpty, omitBy } from 'lodash';

const parseToCorrectState = (obj: any) => {
  const parsed = schemaAppState.safeParse(obj);

  if (parsed.success) {
    return parsed.data;
  } else {
    const fieldErrors = parsed.error.flatten().fieldErrors;

    for (const field in fieldErrors) {
      delete obj[field];
    }
    return obj;
  }
};

export const schemaAppState = z.object({
  stateCode: z
    .preprocess(
      (val) => (Array.isArray(val) ? val : [val]),
      z.array(z.nativeEnum(StateCode))
    )
    .default([]),
  regionGranularity: z.nativeEnum(RegionGranularity),
  visMetric: z.nativeEnum(VisMetric),
  predictionPeriod: z.coerce.number(z.nativeEnum(PredictionPeriod)),
  marketGrowth: z.nativeEnum(MarketGrowth),
  displayType: z.nativeEnum(DisplayType),
  countyMetadataType: z
    .nativeEnum(ECountyMetadata)
    .default(ECountyMetadata.Hidden),
  mapStyle: z.nativeEnum(MapStyle),
  trafficDestinationZips: z
    .preprocess(
      (val) => (Array.isArray(val) ? val : [val]),
      z.array(z.string())
    )
    .default([]),
  showChargingStations: z
    .preprocess((val) => {
      if (typeof val === 'string') {
        return val === 'true';
      } else {
        return val;
      }
    }, z.boolean())
    .default(true),
  selectedTab: z.coerce.string().default(ESelectedTab.Map),
  portBudget: z.coerce.number().default(20),
});

export type AppState = z.infer<typeof schemaAppState>;
export const APPSTATE_DEFAULTS = {
  stateCode: [StateCode.AL, StateCode.GA, StateCode.MS],
  regionGranularity: RegionGranularity.ZipCode,
  visMetric: VisMetric.PercElectricVehicles,
  predictionPeriod: PredictionPeriod.Three,
  marketGrowth: MarketGrowth.Conservative,
  displayType: DisplayType['2D'],
  mapStyle: MapStyle.Default,
  trafficDestinationZips: [],
  showChargingStations: true,
  countyMetadataType: ECountyMetadata.Hidden,
  selectedTab: ESelectedTab.Map,
  portBudget: 20,
} as AppState;

export const _appStateAtom = atom<AppState>(APPSTATE_DEFAULTS);

// Note: could use the onMount but it comes with its own issues
// appStateAtom.onMount = (setAtom) => {
//   setAtom(validateObj(APPSTATE_DEFAULTS));
// };

export const appStateAtom = atom(
  (get) => get(_appStateAtom),
  (get, set, update: Partial<AppState>) => {
    const newState = parseToCorrectState({ ...get(_appStateAtom), ...update });
    set(_appStateAtom, newState);

    if (typeof window !== 'undefined') {
      const params = queryString.stringify(newState, { arrayFormat: 'comma' });
      window.history.pushState(null, '', `?${params}`);
    }
  }
);

/**
 * Update the state in the URL on every state change
 * @returns null
 *
 * @example
 * ```tsx
 * import { useBrowerState } from '@/lib/appStateAtom';
 * useBrowserState();
 *
 * ```
 **/
export function useBrowserStateOnce() {
  const appState = useAtomValue(appStateAtom);

  useEffect(() => {
    const params = queryString.stringify(appState, {
      arrayFormat: 'comma',
    });

    window.history.pushState(null, '', `?${params}`);
  }, []); // only once

  return null;
}

/**
 * Hydrate the app state on page load using defaults and URL params
 * @returns null
 *
 * @example
 * ```tsx
 * import { useHydrateAppState } from '@/lib/appStateAtom';
 * useHydrateAppState();
 *
 * ```
 *
 **/
export const useHydrateAppState = () => {
  const searchParams = useSearchParams();

  const params = omitBy(
    queryString.parse(searchParams.toString(), {
      arrayFormat: 'comma',
    }) as unknown as ExtractAtomValue<typeof appStateAtom>,
    isEmpty
  );

  const state = {
    ...APPSTATE_DEFAULTS,
    ...parseToCorrectState({ ...params }),
  };

  useHydrateAtoms([[appStateAtom, state]]);
};
