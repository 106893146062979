import { Table, Tooltip } from '@mantine/core';
import { useAtomValue } from 'jotai';
import { appStateAtom } from '@/lib/appStateAtom';
import { ECountyMetadata } from '@/lib/types';

export const DataLegend = () => {
  const { countyMetadataType } = useAtomValue(appStateAtom);

  if (countyMetadataType === ECountyMetadata.Demographics) {
    return <CountyDemographicsDataLegend />;
  }
  if (countyMetadataType === ECountyMetadata.EnergyAffinity) {
    return <EnergyAffinityDataLegend />;
  }
  return null;
};

export const CountyDemographicsDataLegend = () => {
  const countyMetadataDemographicsLegend = [
    ['20 - 80', '$30,000 - $40,000', '25% - 70%'],
    ['35 - 480', '$40,000 - $50,000', '15% - 45%'],
    ['185 - 1335', '$60,000 - $75,000', '25% - 60%'],
  ];

  const getLabel = (label: string, index: number) => {
    return (
      <div className="flex flex-col text-lg items-center justify-center">
        <div>{label}</div>
        <div>
          <Table
            className="text-xs text-center"
            data={{
              head: [
                'Population Density',
                'Median Household Income',
                '% Democrat',
              ],
              body: [countyMetadataDemographicsLegend[index]],
            }}
          />
        </div>
        <ol className="list-disc ml-4 my-6 text-xs">
          <li>Population Density: persons per square mile</li>
          <li>Median Household Income: 50th percentile for household income</li>
          <li>
            % Democrat: percentage of a given population voting Democrat vs.
            Republican based on 2020 election returns
          </li>
        </ol>
      </div>
    );
  };
  return (
    <div className="self-center flex flex-col justify-between mb-10">
      <div className="flex">
        <div className="self-start grid grid-cols-1 gap-2 items-center">
          <Tooltip label={getLabel('Low Income, Rural', 0)} position="left">
            <div className="bg-[#E6E647] w-[50px] h-[50px] rounded-md shadow-2xl border-1 border-solid border-gray-200" />
          </Tooltip>
          <Tooltip
            label={getLabel('Middle Income, Suburban', 1)}
            position="left"
          >
            <div className="bg-[#64C863] w-[50px] h-[50px] rounded-md shadow-2xl border-1 border-solid border-gray-200" />
          </Tooltip>
          <Tooltip label={getLabel('High Income, Urban', 2)} position="left">
            <div className="bg-[#3164C8] w-[50px] h-[50px] rounded-md shadow-2xl border-1 border-solid border-gray-200" />
          </Tooltip>
        </div>
      </div>
    </div>
  );
};

export const EnergyAffinityDataLegend = () => {
  const energyAffinityLegend = [['20 - 35'], ['40 - 50'], ['60 - 75']];

  const getLabel = (label: string, index: number) => {
    return (
      <div className="flex flex-col text-lg items-center justify-center">
        <div>{label}</div>
        <div>
          <Table
            className="text-xs text-center"
            data={{
              head: ['Energy Affinity'],
              body: [energyAffinityLegend[index]],
            }}
          />
        </div>
        <ol className="list-disc ml-4 my-6 text-xs">
          <li>
            Energy Affinity: relative affinity for energy efficient technologies
            such as EVs, solar panels, etc (larger value = more interest)
          </li>
        </ol>
      </div>
    );
  };
  return (
    <div className="self-center flex flex-col justify-between pb-10">
      <div className="flex">
        <div className="self-start grid grid-cols-1 gap-2 items-center">
          <Tooltip label={getLabel('Low Interest', 0)} position="left">
            <div className="bg-[#E6E647] w-[50px] h-[50px] rounded-md shadow-2xl border-1 border-solid border-gray-200" />
          </Tooltip>
          <Tooltip label={getLabel('Medium Interest', 1)} position="left">
            <div className="bg-[#64C863] w-[50px] h-[50px] rounded-md shadow-2xl border-1 border-solid border-gray-200" />
          </Tooltip>
          <Tooltip label={getLabel('High Interest', 2)} position="left">
            <div className="bg-[#3164C8] w-[50px] h-[50px] rounded-md shadow-2xl border-1 border-solid border-gray-200" />
          </Tooltip>
        </div>
      </div>
    </div>
  );
};
