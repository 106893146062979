'use client';

import { Menu, ActionIcon, SegmentedControl, RadioGroup } from '@mantine/core';
import {
  IconBaselineDensityMedium,
  IconMap,
  IconTable,
} from '@tabler/icons-react';
import { memo } from 'react';
import Image from 'next/image';
import { appStateAtom } from '@/lib/appStateAtom';
import { useAtom } from 'jotai';
import { ESelectedTab } from '@/lib/types';
import { useAuth } from '@/lib/useAuth';
import { useRouter } from 'next/navigation';

export const Navbar = memo(() => {
  const [appState, setAppState] = useAtom(appStateAtom);
  const { logout } = useAuth();
  const router = useRouter();

  return (
    <nav className="h-[40px] grid grid-cols-[auto_1fr_auto_auto] w-full justify-between border-b-2 px-4 py-1 items-center">
      <div className="flex items-center gap-2">
        <Image
          src={'/ra-logo-v2-icon.svg'}
          alt="EV Planning Hub"
          height={20}
          width={20}
        />
        <div className="text-sm font-bold">EV Planning Hub</div>
      </div>

      <RadioGroup className="m-auto" size="xs">
        <SegmentedControl
          value={appState.selectedTab}
          // fullWidth={true}
          radius="md"
          size="xs"
          className="min-w-[200px]"
          data={[
            {
              label: (
                <div className="flex items-center justify-center gap-1">
                  <IconMap size={12} stroke={1.5} />
                  Map
                </div>
              ),
              value: ESelectedTab.Map,
            },
            {
              label: (
                <div className="flex items-center justify-center gap-1">
                  <IconTable size={12} stroke={1.5}></IconTable>
                  Data
                </div>
              ),
              value: ESelectedTab.Data,
            },
          ]}
          onChange={(value) =>
            setAppState({
              ...appState,
              selectedTab: value,
            })
          }
        />
      </RadioGroup>

      <Image
        src={'/soco-logo.svg'}
        alt="Southern Company"
        height={120}
        width={130}
        className="mr-4"
      />

      <Menu
        transitionProps={{
          transition: 'fade-down',
          duration: 150,
        }}
      >
        <Menu.Target>
          <ActionIcon
            variant="outline"
            color="gray"
            aria-label="Application menu"
          >
            <IconBaselineDensityMedium
              style={{ width: '60%', height: '70%' }}
              size={16}
              stroke={1.5}
            />
          </ActionIcon>
        </Menu.Target>
        <Menu.Dropdown>
          <Menu.Label>Documents</Menu.Label>
          <Menu.Item>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={'/files/Terrata - EV Planning Hub - August 2024.pdf'}
            >
              Release notes
            </a>
          </Menu.Item>
          <Menu.Label>Application</Menu.Label>
          <Menu.Item
            onClick={() => {
              router.push('/change-password');
            }}
          >
            Change Password
          </Menu.Item>
          <Menu.Item
            onClick={async () => {
              await logout();
            }}
          >
            Logout
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>
    </nav>
  );
});
