import terrataAxios from './terrataAxios';
import { RegionGranularity, StateCode, ECountyMetadata } from './types';

export async function getData(
  granularity: RegionGranularity,
  type: 'geo' | 'metric' | 'ev_port_need',
  params: any = {}
) {
  const searchParams = new URLSearchParams();
  Object.entries(params).forEach(([key, value]) => {
    searchParams.set(key, value as string);
  });
  const res = await terrataAxios.get(
    `/${granularity.toLowerCase()}/${type}?${searchParams.toString()}`
  );

  return res.data;
}

export async function getTrafficData(zipcodes: string[]) {
  const params = zipcodes.join(',');
  const res = await terrataAxios.get(`/traffic?zipcodes=${params.toString()}`);
  // TODO: rotate the map when we have traffic data
  return res?.data?.traffic;
}

export async function getChargingStations(stateCode: StateCode[]) {
  const params = stateCode.join(',');
  const res = await terrataAxios.get(
    `/charging_stations?stateCode=${params.toString()}`
  );
  return res?.data?.stations;
}

export async function getCountyMeta(
  stateCode: StateCode[],
  type: ECountyMetadata
) {
  const params = stateCode.join(',');
  const metaType =
    type === ECountyMetadata.Demographics ? 'meta_demo' : 'meta_energy';
  const res = await terrataAxios.get(
    `/county/${metaType}?stateCode=${params.toString()}`
  );
  // TODO: this should be data
  return res?.data?.county_metadata;
}

export async function getAvailableZips(stateCode: StateCode[]) {
  const params = stateCode.join(',');
  const res = await terrataAxios.get(
    `/getAvailableZips?stateCode=${params.toString()}`
  );
  return res?.data;
}
