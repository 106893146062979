import { appStateAtom } from '@/lib/appStateAtom';
import {
  StateCode,
  RegionGranularity,
  VisMetric,
  MarketGrowth,
  DisplayType,
  PredictionPeriod,
  MapStyle,
  ECountyMetadata,
} from '@/lib/types';
import {
  Switch,
  MultiSelect,
  RadioGroup,
  SegmentedControl,
  Select,
  Slider,
} from '@mantine/core';
import { useQuery } from '@tanstack/react-query';
import { useAtom } from 'jotai';
import { useIsFetching } from '@tanstack/react-query';
import { memo, useEffect, useState } from 'react';
import { StateSelect } from './StateSelect';
import { availableZipsQuery } from '@/lib/queries';

const getMinAndMaxValues = (predictionPeriod: PredictionPeriod) => {
  switch (predictionPeriod) {
    case PredictionPeriod.Today:
      return { min: 10, max: 2000 };
    case PredictionPeriod.One:
      return { min: 20, max: 5000 };
    case PredictionPeriod.Three:
      return { min: 100, max: 10000 };
    case PredictionPeriod.Five:
      return { min: 500, max: 20000 };
    case PredictionPeriod.Ten:
      return { min: 1000, max: 40000 };
  }
};

const coercePortBudget = (
  value: number,
  predictionPeriod: PredictionPeriod
) => {
  const { min, max } = getMinAndMaxValues(predictionPeriod);

  return Math.min(Math.max(value, min), max);
};

export const Sidebar = memo(() => {
  const [appState, setAppState] = useAtom(appStateAtom);
  const [_portBudget, setPortBudget] = useState(appState?.portBudget);
  const { data: availableZips } = useQuery(
    availableZipsQuery(appState.stateCode)
  );

  useEffect(() => {
    setPortBudget(
      coercePortBudget(appState.portBudget, appState.predictionPeriod)
    );
  }, [appState.portBudget]);

  const numberOfFetches = useIsFetching();
  const isFetching = numberOfFetches > 0;

  return (
    <div className="flex flex-col h-full p-4 pb-[60px] gap-3 overflow-x-hidden overflow-y-auto border-r-2">
      <StateSelect
        disabled={isFetching}
        data={[
          { label: 'Alabama', value: StateCode.AL },
          { label: 'Georgia', value: StateCode.GA },
          { label: 'Mississippi', value: StateCode.MS },
        ]}
      />
      <RadioGroup label="Region">
        <SegmentedControl
          value={appState.regionGranularity}
          disabled={isFetching}
          fullWidth={true}
          radius="lg"
          data={[
            { label: 'County', value: RegionGranularity.County },
            { label: 'Zip Code', value: RegionGranularity.ZipCode },
          ]}
          onChange={() =>
            setAppState({
              ...appState,
              regionGranularity:
                appState.regionGranularity === RegionGranularity.County
                  ? RegionGranularity.ZipCode
                  : RegionGranularity.County,
            })
          }
        />
      </RadioGroup>
      <Select
        disabled={isFetching}
        label="Prediction Period"
        data={[
          { label: 'Today', value: String(PredictionPeriod.Today) },
          { label: '1 Year', value: String(PredictionPeriod.One) },
          { label: '3 Years', value: String(PredictionPeriod.Three) },
          { label: '5 Years', value: String(PredictionPeriod.Five) },
          { label: '10 Years', value: String(PredictionPeriod.Ten) },
        ]}
        allowDeselect={false}
        comboboxProps={{ offset: 0 }}
        value={String(appState.predictionPeriod)}
        onChange={(value) => {
          // force the portBudget update to be between the min and max values of the new prediction period
          setAppState({
            ...appState,
            predictionPeriod: Number(value),
            portBudget: coercePortBudget(appState.portBudget, Number(value)),
          });
        }}
      />
      <Select
        label="Visualization Metric"
        disabled={isFetching}
        data={[
          {
            label: 'Electric Vehicles',
            value: VisMetric.ElectricVehicles,
          },
          {
            label: '% Electric Vehicles',
            value: VisMetric.PercElectricVehicles,
          },
          { label: 'Net EV Traffic', value: VisMetric.NetEvTraffic },
          { label: 'EV Port Need', value: VisMetric.EvPortNeed },
          { label: 'Energy Affinity', value: VisMetric.EnergyAffinity },
        ]}
        allowDeselect={false}
        comboboxProps={{ offset: 0 }}
        value={appState.visMetric}
        onChange={(value) =>
          setAppState({ ...appState, visMetric: value as VisMetric })
        }
      />
      {appState.visMetric === VisMetric.EvPortNeed && (
        <div className="flex flex-col p-4 gap-4 justify-between border-2 border-solid border-slate-200 rounded-lg">
          <label className="text-xs font-semibold mb-6">
            # of New Ports Available
          </label>

          <Slider
            color="#2CAE4A"
            className="mb-4"
            value={_portBudget}
            onChange={(value) => {
              setPortBudget(value);
            }}
            onChangeEnd={(value) => {
              setAppState({ ...appState, portBudget: value });
            }}
            min={getMinAndMaxValues(appState.predictionPeriod).min}
            max={getMinAndMaxValues(appState.predictionPeriod).max}
            marks={[
              {
                value: getMinAndMaxValues(appState.predictionPeriod).min,
                label: `${getMinAndMaxValues(appState.predictionPeriod).min}`,
              },
              {
                value: getMinAndMaxValues(appState.predictionPeriod).max,
                label: `${getMinAndMaxValues(appState.predictionPeriod).max}`,
              },
            ]}
            labelAlwaysOn
            labelTransitionProps={{
              transition: 'skew-down',
              duration: 150,
              timingFunction: 'linear',
            }}
          />
        </div>
      )}
      <Select
        label="Prediction Growth Rate"
        disabled={isFetching}
        data={[
          {
            label: MarketGrowth.Conservative,
            value: MarketGrowth.Conservative,
          },
          {
            label: MarketGrowth.Moderate,
            value: MarketGrowth.Moderate,
          },
          {
            label: MarketGrowth.Aggressive,
            value: MarketGrowth.Aggressive,
          },
        ]}
        allowDeselect={false}
        comboboxProps={{ offset: 0 }}
        value={appState.marketGrowth}
        onChange={(value) =>
          setAppState({
            ...appState,
            marketGrowth: value as MarketGrowth,
          })
        }
      />

      <Select
        label="Base Map Style"
        disabled={isFetching}
        data={Object.entries(MapStyle).map(([key, value]) => ({
          label: key,
          value,
        }))}
        allowDeselect={false}
        comboboxProps={{ offset: 0 }}
        value={appState.mapStyle}
        onChange={(value) =>
          setAppState({ ...appState, mapStyle: value as MapStyle })
        }
      />

      <Select
        label="Metric display type"
        disabled={
          isFetching ||
          appState.countyMetadataType !== ECountyMetadata.Hidden ||
          appState.trafficDestinationZips.length > 0 ||
          appState.showChargingStations
        }
        data={[
          {
            label: DisplayType['2D'],
            value: DisplayType['2D'],
          },
          {
            label: DisplayType['3D'],
            value: DisplayType['3D'],
          },
        ]}
        allowDeselect={false}
        comboboxProps={{ offset: 0 }}
        value={appState.displayType}
        onChange={(value) =>
          setAppState({
            ...appState,
            displayType: value as DisplayType,
          })
        }
      />

      <Select
        label="County Metadata"
        disabled={isFetching}
        data={[
          {
            label: 'Hidden',
            value: ECountyMetadata.Hidden,
          },
          {
            label: 'Demographics',
            value: ECountyMetadata.Demographics,
          },
          {
            label: 'Energy Affinity',
            value: ECountyMetadata.EnergyAffinity,
          },
        ]}
        allowDeselect={false}
        comboboxProps={{ offset: 0 }}
        value={appState.countyMetadataType}
        onChange={(value) => {
          setAppState({
            ...appState,
            displayType: DisplayType['2D'],
            countyMetadataType: value as ECountyMetadata,
          });
        }}
      />

      <MultiSelect
        data={availableZips?.zipcode ?? []}
        searchable={true}
        disabled={isFetching}
        label="Traffic destination zips"
        placeholder="Select zipcodes"
        // selectFirstOptionOnChange={true}
        value={appState.trafficDestinationZips}
        onChange={(value) => {
          setAppState({
            ...appState,
            displayType: DisplayType['2D'],
            trafficDestinationZips: value,
          });
        }}
      />

      <Switch
        label="Charging stations"
        style={{
          fontWeight: 500,
          marginTop: '8px',
          cursor: 'pointer',
        }}
        classNames={{
          label: '!cursor-pointer',
          track: '!cursor-pointer',
        }}
        color="#2CAE4A"
        disabled={isFetching}
        checked={appState.showChargingStations}
        onChange={(event) => {
          setAppState({
            ...appState,
            displayType: DisplayType['2D'],
            showChargingStations: event.currentTarget.checked,
          });
        }}
      />

      <div className="text-xs text-gray-500">
        *Enabling Extra Layers will override 3D display. Traffic layer only
        available on Zipcode granularity.
      </div>
    </div>
  );
});

Sidebar.displayName = 'Sidebar';
