import * as api from './api';
import {
  RegionGranularity,
  StateCode,
  MarketGrowth,
  VisMetric,
  ECountyMetadata,
} from './types';

export const geoQuery = (regionGranularity: RegionGranularity) => {
  return {
    queryKey: [regionGranularity, 'geo'],
    staleTime: 1000 * 60 * 60 * 24,
    placeholderData: [],
    // placeholderData: (prev) => prev,
    queryFn: () => api.getData(regionGranularity, 'geo'),
    refetchOnWindowFocus: false,
    retry: 1,
  };
};

export const evPortNeedQuery = (
  regionGranularity: RegionGranularity,
  stateCode: StateCode[],
  predictionPeriod: number,
  marketGrowth: MarketGrowth,
  portBudget: number,
  visMetric: VisMetric
) => {
  return {
    queryKey: [
      regionGranularity,
      stateCode.join('_'),
      predictionPeriod,
      marketGrowth,
      portBudget,
      'ev_port_need',
    ],
    placeholderData: [],
    staleTime: 1000 * 60 * 60 * 24,
    queryFn: () =>
      api.getData(regionGranularity, 'ev_port_need', {
        forecastWindow: predictionPeriod,
        marketGrowth,
        portBudget,
        stateCode: stateCode.join(','),
      }),
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    retry: 1,
    enabled: visMetric === VisMetric.EvPortNeed,
  };
};

export const metricQuery = (
  regionGranularity: RegionGranularity,
  stateCode: StateCode[],
  predictionPeriod: number,
  marketGrowth: MarketGrowth,
  visMetric: VisMetric
) => {
  return {
    queryKey: [
      regionGranularity,
      stateCode.join('_'),
      predictionPeriod,
      marketGrowth,
      'metric',
    ],
    staleTime: 1000 * 60 * 60 * 24,
    queryFn: () =>
      api.getData(regionGranularity, 'metric', {
        forecastWindow: predictionPeriod,
        marketGrowth,
        stateCode: stateCode.join(','),
      }),
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    retry: 1,
    enabled: visMetric !== VisMetric.EvPortNeed,
  };
};

export const trafficQuery = (trafficDestinationZips: string[]) => {
  return {
    queryKey: [trafficDestinationZips.join('_'), 'traffic'],
    staleTime: 1000 * 60 * 60 * 24,
    queryFn: () =>
      trafficDestinationZips.length === 0
        ? []
        : api.getTrafficData(trafficDestinationZips),
    refetchOnWindowFocus: false,
    retry: 1,
    enabled: trafficDestinationZips.length > 0,
  };
};

export const chargingStationQuery = (
  showChargingStations: boolean,
  stateCode: StateCode[]
) => {
  return {
    queryKey: [showChargingStations, stateCode.join('_'), 'chargingStations'],
    staleTime: 1000 * 60 * 60 * 24,
    queryFn: () =>
      !showChargingStations ? [] : api.getChargingStations(stateCode),
    refetchOnWindowFocus: false,
    retry: 1,
    enabled: showChargingStations,
  };
};

export const countyMetaQuery = (
  stateCode: StateCode[],
  type: ECountyMetadata
) => {
  return {
    queryKey: [stateCode.join('_'), type, 'countyMetadata'],
    staleTime: 1000 * 60 * 60 * 24,
    queryFn: () =>
      type === ECountyMetadata.Hidden ? [] : api.getCountyMeta(stateCode, type),
    refetchOnWindowFocus: false,
    retry: 1,
    enabled: type !== ECountyMetadata.Hidden,
  };
};

export const availableZipsQuery = (stateCode: StateCode[]) => {
  return {
    queryKey: [stateCode.join('_'), 'availableZips'],
    queryFn: () =>
      stateCode.length === 0 ? [] : api.getAvailableZips(stateCode),
    enabled: stateCode.length > 0,
    retry: 1,
    staleTime: 1000 * 1000 * 60 * 60 * 24,
    refetchOnWindowFocus: false,
  };
};
